import React from "react";
import "./index.css";
import Suitard from "./components/Suitard";
import Navbar from "./components/Navbar";
import TokenomicsSection from "./components/TokenomicsSection";
import LoreSection from "./components/LoreSection";
import TopSuitardosSection from "./components/TopSuitardosSection";
import SafetySection from "./components/SafetySection";
import TelegramSection from "./components/TelegramSection";
import SuiClientWrapper from "./components/Wrapper";
import GameSection from "./components/GameSection";

const App: React.FC = () => {
    return (
        <SuiClientWrapper>
            <div className="min-h-screen text-white bg-white">
                <Navbar />
                <Suitard />
                <GameSection />
                <TokenomicsSection />
                <LoreSection />
                <TopSuitardosSection />
                <SafetySection />
                <TelegramSection />
            </div>
        </SuiClientWrapper>
    );
};

export default App;
