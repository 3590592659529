import { bcs } from "@mysten/sui/bcs";
import { getFullnodeUrl, SuiClient } from "@mysten/sui/client";
import { Transaction } from "@mysten/sui/transactions";

const CR = "0x84177060b8fbd63734d16736a4970dbf132462fec545cd172b666874d69b3e25";

const STD_TYPE =
    "0x2cddfc6d4fc855917e990e71cd122b1ee8098aa890186ee15a84524ed17cd8c9::suitard::SUITARD";

export const claimTx = (address: string, tx: Transaction) => {
    const ret = tx.moveCall({
        target: "0xbb4635744cac3f0706ad374a2008e7c71c86dbad5797a4b872379c805f50557b::distributor::claim",
        arguments: [tx.object(CR)],
    });

    const coin = tx.moveCall({
        target: "0x2::coin::from_balance",
        typeArguments: [STD_TYPE],
        arguments: [ret],
    });

    tx.transferObjects([coin], address);
};

export const checkHasClaimed = async (address: string) => {
    const client = new SuiClient({ url: getFullnodeUrl("mainnet") });

    const tx = new Transaction();

    tx.moveCall({
        target: "0xbb4635744cac3f0706ad374a2008e7c71c86dbad5797a4b872379c805f50557b::distributor::has_claimed",
        arguments: [tx.object(CR), tx.pure.address(address)],
    });

    const res = await client.devInspectTransactionBlock({
        transactionBlock: tx,
        sender: address,
        additionalArgs: { showRawTxnDataAndEffects: true },
    });

    console.log(res);

    const hasClaimed = new Uint8Array(
        res?.results?.[0]?.returnValues?.[0]?.[0]!
    );

    console.log(hasClaimed);

    return bcs.bool().parse(hasClaimed);
};
