import React, { useState, useEffect } from "react";

const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const navItems = [
        { href: "#about", text: "About" },
        { href: "#tokenomics", text: "Tokenomics" },
        {
            href: "https://app.cetus.zone/swap?from=0x2::sui::SUI&to=0x2cddfc6d4fc855917e990e71cd122b1ee8098aa890186ee15a84524ed17cd8c9::suitard::SUITARD",
            text: "Buy",
        },
        { href: "#airdrop", text: "Airdrop" },
        { href: "#socials", text: "Socials" },
    ];

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkIsMobile();
        window.addEventListener("resize", checkIsMobile);

        return () => window.removeEventListener("resize", checkIsMobile);
    }, []);

    const renderNavItems = () =>
        navItems.map((item) => (
            <a
                key={item.href}
                href={item.href}
                target={item.text === "Buy" ? "_blank" : undefined}
                className={`nav-text text-xl ${item.href === "#buy" && !isMobile ? "btn-layered" : ""
                    }`}
                onClick={() => setIsOpen(false)} rel="noreferrer"
            >
                <span>{item.text}</span>
            </a>
        ));

    return (
        <nav className="md:py-10 md:px-6 pt-4 px-5 absolute top-0 left-1/2 -translate-x-1/2 w-full md:w-3/5 z-50">
            <div className="container flex justify-between items-center">
                {isMobile ? (
                    <>
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="text-white focus:outline-none md:hidden"
                        >
                            <svg
                                className="w-10 h-10"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        </button>
                        {isOpen && (
                            <div className="absolute top-full flex flex-col gap-6 bg-black left-0 right-0 shadow-lg py-4 px-6">
                                {renderNavItems()}
                            </div>
                        )}
                    </>
                ) : (
                    renderNavItems()
                )}
            </div>
        </nav>
    );
};

export default Navbar;
