import React from "react";

const TelegramSection: React.FC = () => {
    return (
        <section className="relative py-10 md:mt-20" id="socials">
            <div className="container mx-auto text-black relative flex flex-col gap-20 md:gap-0 md:flex-row items-center py-20">
                {/* Content */}
                <div className="flex flex-col justify-between gap-10 flex-1">
                    <div className="flex items-center border-b border-gray-300 ">
                        {/* <span className="text-red-500 text-2xl mr-4">★</span> */}
                        <a
                            className="text-[64px] mr-4 text-black hover:text-blue-400 transition-colors duration-200 font-franchise cursor-pointer"
                            href="https://t.me/suitard"
                            target="_blank"
                        >
                            TELEGRAM
                        </a>
                    </div>
                    <div className="flex items-center border-b border-gray-300">
                        <a
                            className="text-[64px] mr-4 text-black hover:text-blue-400 transition-colors duration-200 font-franchise cursor-pointer"
                            href="https://birdeye.so/token/0x2cddfc6d4fc855917e990e71cd122b1ee8098aa890186ee15a84524ed17cd8c9::suitard::SUITARD/0x960d673274597d9ac471044d4a94740dacd9e5039217397ea44cd9f994ff55de?chain=sui"
                            target="_blank"
                        >
                            BIRD EYE
                        </a>
                        {/* <span className="text-black text-2xl group-hover:text-blue-500 transition-colors duration-300">↗</span> */}
                    </div>
                    <div className="flex items-center border-b border-gray-300 ">
                        {/* <span className="text-red-500 text-2xl mr-4">★</span> */}
                        <a
                            className="text-[64px] mr-4 text-black hover:text-blue-400 transition-colors duration-200 font-franchise cursor-pointer"
                            href="https://x.com/retardSuitizen"
                            target="_blank"
                        >
                            TWITTER
                        </a>
                    </div>
                    <div>
                        <a
                            className="text-[64px] mr-4 text-black hover:text-blue-400 transition-colors duration-200 font-franchise cursor-pointer"
                            href="https://suivision.xyz/coin/0x2cddfc6d4fc855917e990e71cd122b1ee8098aa890186ee15a84524ed17cd8c9::suitard::SUITARD"
                            target="_blank"
                        >
                            CONTRACT
                        </a>
                    </div>
                </div>
                <div className="flex justify-end">
                    <div className="transform -rotate-12 z-30 border-4 border-black md:w-[300px] w-[170px] -mr-20">
                        <img
                            src="/assets/suitard-1.png"
                            alt="Shoe 1"
                            className=" object-cover shadow-lg"
                        />
                    </div>
                    <div className="transform z-20 border-4 border-black md:w-[300px] w-[170px] bottom-10">
                        <img
                            src="/assets/suitard-2.png"
                            alt="Shoe 2"
                            className=" object-cover shadow-lg"
                        />
                    </div>
                    <div className="transform rotate-12 z-10 border-4 border-black md:w-[300px] w-[170px] -ml-20">
                        <img
                            src="/assets/suitard-3.png"
                            alt="Shoe 3"
                            className=" object-cover shadow-lg"
                        />
                    </div>
                </div>
                {/* Full-width border lines */}
                {/* <div className="absolute inset-0 flex flex-col justify-between pointer-events-none">
                    <div className="border-b border-gray-300 w-screen"></div>
                    <div className="border-b border-gray-300 w-screen"></div>
                    <div className="border-b border-gray-300 w-screen"></div>
                    <div className="border-b border-gray-300 w-screen"></div>
                </div> */}
            </div>
        </section>
    );
};

export default TelegramSection;
