import { bcs } from "@mysten/sui/bcs";
import { getFullnodeUrl, PaginatedCoins, SuiClient } from "@mysten/sui/client";
import { Transaction } from "@mysten/sui/transactions";
import { normalizeSuiObjectId } from "@mysten/sui/utils";

const packageId =
    "0x048e7e670089e509a358b74ef8b239ca203b637f9f083f599a18a25fc858f4de";

const lastTardRemaining =
    "0x5e821ef0caab4ed0fb880a59fe3408a305cba989caf0ba97afe3156a73046c9d";

const tard =
    "0xee5bf16d82b864c87fe9ea272cbde5604e352853c2ae0d5dfe65b930d145cacf";

const STD_TYPE =
    "0x2cddfc6d4fc855917e990e71cd122b1ee8098aa890186ee15a84524ed17cd8c9::suitard::SUITARD";

export const depositTx = async (
    address: string,
    tx: Transaction,
    client: SuiClient
) => {
    const userCoins = await getAllUserCoins({
        address,
        type: STD_TYPE,
        client,
    });
    const inputCoin = await getExactCoinByAmount(
        STD_TYPE,
        userCoins.map((item) => ({
            balance: BigInt(item.balance),
            objectId: item.coinObjectId,
        })),
        BigInt(69 * 10 ** 9),
        tx
    );

    const balance = tx.moveCall({
        target: `0x2::coin::into_balance`,
        arguments: [inputCoin],
        typeArguments: [STD_TYPE],
    });

    tx.moveCall({
        target: `${packageId}::last_tards_standing::boink`,
        arguments: [
            tx.object(lastTardRemaining),
            balance,
            tx.object(normalizeSuiObjectId("0x6")),
        ],
    });

    const coin = tx.moveCall({
        target: `0x2::coin::from_balance`,
        arguments: [balance],
        typeArguments: [STD_TYPE],
    });

    tx.transferObjects([coin], address);
};

export const getGameData = async () => {
    const client = new SuiClient({ url: getFullnodeUrl("mainnet") });

    const data = await client.getObject({
        id: lastTardRemaining,
        options: {
            showContent: true,
        },
    });

    const fields = (data.data?.content as any).fields;

    const gameData = {
        endtime: fields.end_time,
        lastTardRemainingBalance: fields.booty,
        totalTards: fields.total_boinks,
    };

    return gameData;
};

const getExactCoinByAmount = async (
    coinType: string,
    coins: { objectId: string; balance: bigint }[],
    amount: bigint,
    txb: Transaction
) => {
    const coinsX = getCoinsGreaterThanAmount(amount, coins);

    if (coinsX.length > 1) {
        txb.mergeCoins(
            txb.object(coinsX[0]),
            coinsX.slice(1).map((coin) => txb.object(coin))
        );
    }

    const [coinA] = txb.splitCoins(txb.object(coinsX[0]), [
        txb.pure.u64(amount),
    ]);
    return coinA;
};

const getCoinsGreaterThanAmount = (
    amount: bigint,
    coins: { objectId: string; balance: bigint }[]
) => {
    const coinsWithBalance: string[] = [];

    let collectedAmount = BigInt(0);

    for (const coin of coins) {
        if (
            collectedAmount < amount &&
            !coinsWithBalance.includes(coin.objectId)
        ) {
            coinsWithBalance.push(coin.objectId);
            collectedAmount = collectedAmount + coin.balance;
        }
        if (
            coin.balance === BigInt(0) &&
            !coinsWithBalance.includes(coin.objectId)
        )
            coinsWithBalance.push(coin.objectId);
    }

    if (collectedAmount >= amount) {
        return coinsWithBalance;
    } else {
        throw new Error("Insufficient balance");
    }
};

const getAllUserCoins = async ({
    address,
    type,
    client,
}: {
    type: string;
    address: string;
    client: SuiClient;
}) => {
    let cursor: string | null | undefined = null;

    let coins: any[] = [];
    let iter = 0;

    do {
        try {
            const res: PaginatedCoins = await client.getCoins({
                owner: address,
                coinType: type,
                cursor: cursor,
                limit: 50,
            });
            coins = coins.concat(res.data);
            cursor = res.nextCursor;
            if (!res.hasNextPage || iter === 8) {
                cursor = null;
            }
        } catch (error) {
            console.log(error);
            cursor = null;
        }
        iter++;
    } while (cursor !== null);

    return coins;
};
