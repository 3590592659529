import {
    ConnectButton,
    ConnectModal,
    useCurrentAccount,
    useSignTransaction,
} from "@mysten/dapp-kit";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { checkHasClaimed, claimTx } from "../txns/claimTxns";
import { Transaction } from "@mysten/sui/transactions";
import { SuiClient } from "@mysten/sui/client";

const tards = [
    "mrbreadsmith.sui",
    "matteo.sui",
    "droppy.sui",
    "drippy.sui",
    "death.sui",
    "0xrooter.sui",
    "adeniyi.sui",
    "vlp.sui",
    "suibigbrain.sui",
    "wara.sui",
    "kotaro.sui",
    "wade.sui",
    "shoto.sui",
    "yuppibaladam.sui",
    "JoshuaOrhue.sui",
    "martyparty.sui",
    "cleanwater.sui",
    "Lebinxy.sui",
    "nefarii.sui",
    "bonkman.sui",
    "cussy.sui",
    "dave.sui",
    "trevinvsnft.sui",
    "fesal.sui",
    "demented.sui",
    "777.sui",
    "stravia.sui",
    "069.sui",
    "0xethernity.sui",
    "monreal.sui",
    "b1gvini.sui",
    "angelo.sui",
    "00smurf.sui",
    "jay-p.sui",
    "michaelburry.sui",
    "dragnipur.sui",
    "770.sui",
    "matrixrick.sui",
    "maxim.sui",
    "Thibaut.sui",
    "xpsyk0w.sui",
    "originalmijo.sui",
    "Zibah.sui",
    "RAGNA.sui",
    "meimei.sui",
    "Bert.sui",
    "marcopitra.sui",
    "bullishify.sui",
    "DegenEmperor.sui",
];

const tardAddresses = [
    "0xdcd052001b59ee0174c1258107e138f5b06d48d86d4f24adbfe2d14d742d4fab",
    "0x92aa50d80ad6bb4c6383c1a09fc6a8fe8272a6ae6fce21605de1f466d7456805",
    "0xf71140b0089ad50c0b446f321f8a18819ec51a7881ecf694103ae37ac47fdcc4",
    "0x8e6de426521587efe28a0837567a030fb217f7e21bac6c9e18dc148e5e8a2c87",
    "0xb871a42470b59c7184033a688f883cf24eb5e66eae1db62319bab27adb30d031",
    "0xcf3ed4d49d4dea20d70b7ecc9159f7eb258d7d11afbd43f23fafebfbc26ced32",
    "0x1eb7c57e3f2bd0fc6cb9dcffd143ea957e4d98f805c358733f76dee0667fe0b1",
    "0x5c9b99c97a304c639f73f8e9b1df98aa8f96f03082ce26ed3f52177db8d0e3d3",
    "0xc44bf0dec6431d3e7687db4b607a45e960b6da97db1b5b061fda001c49662034",
    "0xfa1d64cde7526b75a4d04c1804e00fb694bfd9e8a95bb18fdde1883b3aa5a93e",
    "0x10eefc7a3070baa5d72f602a0c89d7b1cb2fcc0b101cf55e6a70e3edb6229f8b",
    "0x08256b51ffc657f02b8ff6e424723869abd521020d70f48f77dd497302a40aa6",
    "0xb36afa413adec9ce62fb67f8784091b7eba2df44e6c6fb0fdefeff511046315b",
    "0xf5a6f95e21917272a1e2858211ed6c9d349892db3652c6d3e847a9dc93ed312a",
    "0xfea3e95fefaae7a4396d65909c4611220de51ee6819098702ccfcf1a87e4f998",
    "0x6338d7a2f318f559b96e2a26b01d2acd675aa3af42192c49b52fd7394626b4fc",
    "0x74bd991f694eb5792c10c3715a125629da67a0494e95b299ae23264210a53cf5",
    "0x59f3bce4a75b7713fc06a7070da637768013f6a30cf487c6150c752cebe00223",
    "0xc1b4abd6ec3c8417bea91cdb517dcbffc2cd963ffc9b76056c09183f55486042",
    "0x4466fe25550f648a4acd6823a90e1f96c77e1d37257ee3ed2d6e02a694984f73",
    "0x9f279c8467608d6aa55c9514135bb4921a5faf0c6335e3b3e5096ac7cb1cd251",
    "0xb53a1af6c90f0f023bf93308ea87a934267933ff9cf94c50076000c250f41361",
    "0x750efb8f6d1622213402354bfafb986ac5674c2066e961badf83c7ccd2dc5505",
    "0x7f9c74a94069e980195fe4b75eb75b0bfd4f91d008da8e8f99b019fa135c7df2",
    "0x4f21032bd4257c2d67a8f7c7bf8055f3c9516b57bb777cb0a724bb16adff63df",
    "0x964d3d01b9b433cefa1ce518f05c825e6a7c61c3925ae13c2643aaaa9688ebda",
    "0x0f6d76fd452959c8f32f11b1033f499a1bb223e79e0548b82526b9da7403c829",
    "0xcaa4e9656f285b64e69929467302c12105364c1ee9cc7091c0cf61a7423dd20f",
    "0xb8224ba4ae2df683d880dc58a4e46e073e973bfd86eaddbb4da63f78e6c04877",
    "0x92050cf56c9d4a7b442ddda86dcb4c6642ab5cc12cba82f7abd7494c1be368dd",
    "0x8c3026cdca74f2dfaece33c1fc311f1c95fd0a3484114c1764035a62d81e30bc",
    "0xb9bc14a75a633e84b06372a0dd373f610e0d183c4232b53f9a1ad58bda3653bc",
    "0xb9899d93350d396aa9f695206ca2fc1507fb282b79294f43c3962ff8de78ee58",
    "0x68691f8e1609c91b389a55d0c7e02f426df4d79b433c6717200d4444cae3311c",
    "0x9dc57cf0fcd21e224d537f37b3ae25e2b1487b9b7c326f11e2c75a65492ab091",
    "0x24d14cac30255099cb65ea8f357562556716767aa758b62cb579db3643cfc0d7",
    "0x6a5f52aa39b41b9c9d2a1bff17f13d196088f0b64fa8e29076efe025ad49a46b",
    "0xf5ad86df467d1d945072aafc047413b8cf9e6237f9505ea63c3612ee7ecf0306",
    "0xe6e494d014eb41edacae84bfc5893ab5616be246064d52b452ba88828a548b8b",
    "0x8963bf7bb0d4ec150634de510234f2001a339b6d6774f890c8e1ff7d34a6eb7b",
    "0xca8229067b1cb9e75618c7e6d6203c2e7cdb90cbbbfed8935c81a90765d2f039",
    "0xd0f36ac29773c41e44ebdbaafe533483d12aa530bea537bfe65b32161901e74d",
    "0x8cfed27f37d4c465129577a88afe257ebac56b1bd10cfd5f7858b1915d367a20",
    "0x74fed224663d295ba11e22522c53d76b09f7fa6ed1cce7ecf866c8edf417666e",
    "0xf0d601dccd48ef448df3018e4e18478392c2971b7456be637cad1b6acee5795f",
    "0x48dc3996bd29f9b3bceacfd3e768ad3028870d7a5561e9646ac7315d6609874d",
    "0xc23ea8e493616b1510d9405ce05593f8bd1fb30f44f92303ab2c54f6c8680ecb",
    "0xaf08e6fabbabbe3f05e3a1dcc164b65382b170e4102fc94bf4a51289ac7bba44",
    "0xc88d057c75a9d64ac3760cd2e35fc5314fa143eae74dc419de07a023b04c87fb",
    "0x245a2f56847ac73bce60a2e4d4cb78fcf0c1213846c53ffeda72ff2719fba11a",
];

const TopSuitardosSection: React.FC = () => {
    const currentAccount = useCurrentAccount();
    const { mutateAsync: signTransaction } = useSignTransaction();

    const carouselRef = useRef<HTMLDivElement>(null);
    const carouselRef1 = useRef<HTMLDivElement>(null);
    const [isHovering, setIsHovering] = useState(false);
    const animationRef = useRef<number | null>(null);
    const animationRef1 = useRef<number | null>(null);

    const [hasClaimed, setHasClaimed] = useState(true);
    const [claiming, setClaiming] = useState(false);

    const fetchhasClaimed = useCallback(async () => {
        if (currentAccount?.address) {
            if (tardAddresses.indexOf(currentAccount.address) !== -1) {
                const res = await checkHasClaimed(currentAccount?.address);

                console.log(res);

                setHasClaimed(res);
            }
        }
    }, [currentAccount?.address]);

    useEffect(() => {
        fetchhasClaimed();
    }, [fetchhasClaimed]);

    const claim = async () => {
        if (!currentAccount?.address) return;

        try {
            const tx = new Transaction();
            const client = new SuiClient({
                url: "https://fullnode.mainnet.sui.io:443",
            });

            setClaiming(true);

            claimTx(currentAccount?.address, tx);

            const { bytes, signature, reportTransactionEffects } =
                await signTransaction({
                    transaction: tx,
                });

            const executeResult = await client.executeTransactionBlock({
                transactionBlock: bytes,
                signature,
                options: {
                    showRawEffects: true,
                },
                requestType: "WaitForEffectsCert",
            });

            console.log(executeResult);

            setTimeout(() => {
                fetchhasClaimed();
            }, 1000);
        } catch (e) {
            console.log(e);
        } finally {
            setTimeout(() => {
                setClaiming(false);
            }, 1000);
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;
        if (!carousel) return;

        let clone: HTMLDivElement | null = null;

        const animateCarousel = () => {
            if (!carousel) return;
            if (carousel.scrollLeft >= carousel.scrollWidth / 2) {
                carousel.scrollLeft = 0;
            } else {
                carousel.scrollLeft += 1;
            }
            animationRef.current = requestAnimationFrame(animateCarousel);
        };

        const startAnimation = () => {
            if (!isHovering) {
                animationRef.current = requestAnimationFrame(animateCarousel);
            }
        };

        const stopAnimation = () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
                animationRef.current = null;
            }
        };

        if (!isHovering) {
            clone = carousel.cloneNode(true) as HTMLDivElement;
            carousel.appendChild(clone);
            startAnimation();
        } else {
            stopAnimation();
        }

        return () => {
            stopAnimation();
            if (clone) {
                carousel.removeChild(clone);
                clone = null;
            }
        };
    }, [isHovering]);

    useEffect(() => {
        const carousel = carouselRef1.current;
        if (!carousel) return;

        let clone: HTMLDivElement | null = null;

        const animateCarousel = () => {
            if (!carousel) return;
            if (carousel.scrollLeft >= carousel.scrollWidth / 2) {
                carousel.scrollLeft = 0;
            } else {
                carousel.scrollLeft += 1;
            }
            animationRef1.current = requestAnimationFrame(animateCarousel);
        };

        const startAnimation = () => {
            if (!isHovering) {
                animationRef1.current = requestAnimationFrame(animateCarousel);
            }
        };

        const stopAnimation = () => {
            if (animationRef1.current) {
                cancelAnimationFrame(animationRef1.current);
                animationRef1.current = null;
            }
        };

        if (!isHovering) {
            clone = carousel.cloneNode(true) as HTMLDivElement;
            carousel.appendChild(clone);
            startAnimation();
        } else {
            stopAnimation();
        }

        return () => {
            stopAnimation();
            if (clone) {
                carousel.removeChild(clone);
                clone = null;
            }
        };
    }, [isHovering]);

    return (
        <section className="py-10 text-black" id="airdrop">
            <div className="container mx-auto overflow-clip">
                <div className="flex flex-col lg:flex-row gap-3 items-start lg:items-end pl-8">
                    <div className="flex flex-col md:items-start">
                        <h2 className="text-[72px] lg:text-[100px] text-start lg:text-center font-['Franchise']">
                            TOP
                        </h2>
                        <h2 className="text-[72px] lg:text-[100px] text-start lg:text-center font-['Franchise'] -mt-10">
                            SUITARDiOS
                        </h2>
                    </div>
                    {!currentAccount?.address ? (
                        <div className="relative mb-10 lg:ml-16">
                            <ConnectButton
                                className="btn-layered"
                                //
                                connectText={"Check Eligibility"}
                            />
                            <p className="absolute bottom-0 z-10 text-center w-full top-2 text-white pointer-events-none ">
                                Check Eligibility
                            </p>
                        </div>
                    ) : (
                        <button
                            className="rounded btn-layered text-black mb-10 ml-4 lg:ml-16"
                            onClick={() => {
                                if (
                                    tardAddresses.includes(
                                        currentAccount.address
                                    ) &&
                                    !hasClaimed
                                ) {
                                    claim();
                                }
                            }}
                        >
                            <span>
                                {!claiming
                                    ? tardAddresses.includes(
                                          currentAccount.address
                                      )
                                        ? !hasClaimed
                                            ? "Claim"
                                            : "Already claimed!"
                                        : "Not Eligible"
                                    : "Claiming..."}
                            </span>
                        </button>
                    )}
                </div>

                <div
                    ref={carouselRef}
                    className="flex overflow-hidden"
                    style={{ width: "100%" }}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                >
                    {tards.slice(0, 25).map((name, index) => (
                        <div
                            key={`${name}-${index}`}
                            className="flex-shrink-0 w-48 mx-4"
                        >
                            <div className="text-center">
                                <div className="flex flex-col items-center border-4 border-black">
                                    <img
                                        src={`/assets/${index + 1}.jpg`}
                                        alt={name}
                                        className="w-48 h-48 object-cover"
                                    />
                                </div>
                                <p className="font-bold">{name}</p>
                                {/* <button className="px-4 py-2 rounded mt-2 btn-layered text-black">
                                    <span>Claim</span>
                                </button> */}
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    ref={carouselRef1}
                    className="flex overflow-hidden mt-6"
                    style={{ width: "100%" }}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                >
                    {tards.slice(25).map((name, index) => (
                        <div
                            key={`${name}-${index}`}
                            className="flex-shrink-0 w-48 mx-4"
                        >
                            <div className="text-center">
                                <div className="flex flex-col items-center border-4 border-black">
                                    <img
                                        src={`/assets/${25 + 1 + index}.jpg`}
                                        alt={name}
                                        className="w-48 h-48 object-cover"
                                    />
                                </div>
                                <p className="font-bold">{name}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TopSuitardosSection;
