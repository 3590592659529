import {
    createNetworkConfig,
    SuiClientProvider,
    WalletProvider,
} from "@mysten/dapp-kit";
import { getFullnodeUrl } from "@mysten/sui/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Config options for the networks you want to connect to
// @ts-ignore
const { networkConfig } = createNetworkConfig({
    testnet: { url: getFullnodeUrl("testnet") },
    mainnet: { url: getFullnodeUrl("mainnet") },
});
const queryClient = new QueryClient();

interface IPropType {
    children: React.ReactNode;
}

const SuiClientWrapper = (props: IPropType) => {
    return (
        <QueryClientProvider client={queryClient}>
            <SuiClientProvider
                networks={networkConfig}
                defaultNetwork="mainnet"
            >
                <WalletProvider
                    autoConnect
                    stashedWallet={{
                        name: "Kriya",
                    }}
                >
                    {props.children}
                </WalletProvider>
            </SuiClientProvider>
        </QueryClientProvider>
    );
};

export default SuiClientWrapper;
