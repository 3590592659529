import React, { useState, useEffect, useCallback } from "react";
import { depositTx, getGameData } from "../txns/depositTxns";
import { ConnectButton, useCurrentAccount, useSignTransaction } from "@mysten/dapp-kit";
import { Transaction } from "@mysten/sui/transactions";
import { getFullnodeUrl, SuiClient } from "@mysten/sui/client";

const GameSection: React.FC = () => {
    const currentAccount = useCurrentAccount();
    const { mutateAsync: signTransaction } = useSignTransaction();
    const [stdPool, setStdPool] = useState(0);
    const [totalTards, setTotalTards] = useState(0);
    const [isGameOver, setIsGameOver] = useState(false);
    const [timeLeft, setTimeLeft] = useState({
        days: 6,
        hours: 10,
        minutes: 18,
        seconds: 29
    });
    const [isDepositing, setIsDepositing] = useState(false);

    const depositSTD = async () => {
        setIsDepositing(true);
        if (!currentAccount?.address) {
            setIsDepositing(false);
            return;
        }
        try {
            const client = new SuiClient({ url: getFullnodeUrl("mainnet") });
            const tx = new Transaction();
            await depositTx(currentAccount?.address, tx, client);

            const { bytes, signature, reportTransactionEffects } =
                await signTransaction({
                    transaction: tx,
                });

            const executeResult = await client.executeTransactionBlock({
                transactionBlock: bytes,
                signature,
                options: {
                    showRawEffects: true,
                },
                requestType: "WaitForEffectsCert",
            });
            reportTransactionEffects(executeResult.rawEffects!.toString());
        } catch (error) {
            setIsDepositing(false);
            console.error("Failed to deposit STD:", error);
        } finally {
            setIsDepositing(false);
        }
    };

    const updateTimer = useCallback(() => {
        setTimeLeft((prev) => {
            if (prev.seconds > 0) {
                return { ...prev, seconds: prev.seconds - 1 };
            } else if (prev.minutes > 0) {
                return { ...prev, minutes: prev.minutes - 1, seconds: 59 };
            } else if (prev.hours > 0) {
                return { ...prev, hours: prev.hours - 1, minutes: 59, seconds: 59 };
            } else if (prev.days > 0) {
                return { ...prev, days: prev.days - 1, hours: 23, minutes: 59, seconds: 59 };
            }
            return prev;
        });
    }, []);

    useEffect(() => {
        const fetchAndUpdateGameData = async () => {
            const gameData = await getGameData();
            setStdPool(Number(gameData.lastTardRemainingBalance) / 1e9);
            const endTime = new Date(Number(gameData.endtime));
            const now = new Date();
            const timeDiff = endTime.getTime() - now.getTime();
            setTotalTards(Number(gameData.totalTards));

            if (endTime < now && totalTards >= 10) {
                setIsGameOver(true);
            }

            if (timeDiff > 0 && totalTards >= 10) {
                const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

                setTimeLeft({ days, hours, minutes, seconds });
            } else {
                setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            }
        };

        fetchAndUpdateGameData();
        const gameDataInterval = setInterval(fetchAndUpdateGameData, 5000);
        const timerInterval = setInterval(updateTimer, 1000);

        return () => {
            clearInterval(gameDataInterval);
            clearInterval(timerInterval);
        };
    }, [updateTimer, totalTards]);

    return (
        <section className="py-10 text-black" id="game">
            <div className="container mx-auto w-[75%]">
                <h2 className="text-[72px] font-['Franchise'] text-center mb-10">Suitardio Fingerbang</h2>
                <div className="flex flex-col md:flex-row justify-between md:gap-64 gap-10 relative">
                    {/* Left side: Timer, Button, and TVL */}
                    <div className="md:w-1/2 items-center flex flex-col justify-start -mt-3">
                        <div className="text-center">
                            <p className="text-[90px] font-['Franchise']">{stdPool.toLocaleString()} STD</p>
                            <p className="text-lg font-semibold -mt-6">prize pool</p>
                        </div>
                        <div className="flex justify-center space-x-4 my-8">
                            {Object.entries(timeLeft).map(([unit, value]) => (
                                <div key={unit} className="flex flex-col items-center">
                                    <div className="bg-white rounded-lg shadow-lg w-20 h-20 flex items-center justify-center mb-2">
                                        <span className="text-4xl font-bold">{value.toString().padStart(2, '0')}</span>
                                    </div>
                                    <span className="text-lg capitalize font-semibold">{unit}</span>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-center">
                            {!currentAccount?.address ? (
                                <div className="relative">
                                    <ConnectButton
                                        className="btn-layered"
                                        connectText={"Spread STD"}
                                    />
                                    <p className="absolute bottom-0 z-10 text-center w-full top-2 text-white pointer-events-none ">
                                        Connect wallet
                                    </p>
                                </div>
                            ) : (
                                <button
                                    className="rounded btn-layered text-white font-semibold text-[24px] px-8 py-3"
                                    onClick={depositSTD}
                                    disabled={isDepositing || isGameOver}
                                >
                                    <span>
                                        {isGameOver ? "Game Over!" : isDepositing ? "Depositing..." : "BOINK!!"}
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>
                    <img src="/assets/std_bong.png" alt="STD Bong" className="md:absolute md:bottom-0 md:left-1/2 transform md:-translate-x-1/2 md:w-[300px]" />

                    {/* Right side: How It Works explanation */}
                    <div className="md:w-1/2">
                        <h3 className="text-[48px] font-['Franchise'] mb-4">How It Works</h3>
                        <ul className="">
                            <li className="flex items-start gap-2">
                                <span className="text-xl font-bold -mt-0.5">{'>'}</span> a 'boink' costs 69 $STD, it goes into a pool
                            </li>
                            <li className="flex items-start gap-2">
                                <span className="text-xl font-bold -mt-0.5">{'>'}</span>you sign a txn in your wallet every time you boink
                            </li>
                            <li className="flex items-start gap-2">
                                <span className="text-xl font-bold -mt-0.5">{'>'}</span>there's a timer on the screen, its not for nothing
                            </li>

                            <li className="flex items-start gap-2">
                                <span className="text-xl font-bold -mt-0.5">{'>'}</span>every time someone boinks, the timer moves 1 minute forward
                            </li>
                            <li className="flex items-start gap-2">
                                <span className="text-xl font-bold -mt-0.5">{'>'}</span>wen no one clicks for long enough and the timer ends, the last 10 suitards to boink get an equal share of the prize pool
                            </li>
                            <li className="flex items-start gap-2">
                                <span className="text-xl font-bold -mt-0.5">{'>'}</span>prize pool = 80% of total $STD in the pool, 20% $STD will be burnt
                            </li>
                            <li className="flex items-start gap-2">
                                <span className="text-xl font-bold -mt-0.5">{'>'}</span>this game can theoretically go on forever, all the best! fingerbanging sui tar dios
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GameSection;