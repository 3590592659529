import React from "react";

const LoreSection: React.FC = () => {
    return (
        <section className="py-10 text-black" id="about">
            <div className="container mx-auto flex flex-col md:flex-row gap-20 items-center">
                <div className="md:w-2/3 -ml-12 md:ml-0">
                    <img src="/assets/lore.svg" alt="Lore" className="w-full" />
                </div>
                <div className="md:pl-10 md:w-2/3 px-10 md:px-0">
                    <h2 className="text-[96px] mb-4 font-['Franchise']">
                        THE LORE
                    </h2>
                    <p className="text-lg mt-2">
                        It was 2023. FTX was dust, BTC was scraping 19k. Retail
                        had long vanished, but a few die-hard retard(io)s
                        refused to admit they missed their shot. They were still
                        slugging it out in the trenches. A bunch of those
                        retards went full retard and stumbled upon Sui.{" "}
                    </p>
                    <p className="text-lg mt-4">
                        They could sense their thirst for real innovation and
                        asymmetric gainz in brYpTO was finally coming true. They
                        started grinding and kept grinding. Cringe $FAPTOS went
                        to 20 and but these retards? They kept holding capys,
                        bullsharks and narwhals in hope of magical airdrop
                        payday.
                    </p>
                    <p className="text-lg mt-4">
                        Nothing happened ofc, eggdeniyi did his best but no one
                        is big enough to fight the wrath of a bear market. The
                        game is to survive! eggdeniyi became dank and degenerate
                        over time, from #noairdropnocommunity he went to a blub
                        blub blub guy. And same happened with those retard
                        users.
                    </p>
                    <p className="text-lg mt-4">
                        The farmers fucked off to get fucked by another L1/L2/L3
                        but the retards who stuck? Oh they made it! They’re
                        buying random shit and raking profits, they’re aping
                        gaming devices, hitting parties with Steve aoki! They;re
                        no longer your ‘cutesy’ suifrens anymore, they’re
                        suitards!{" "}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default LoreSection;
