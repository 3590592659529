import React, { useState, useEffect } from "react";

const Suitard: React.FC = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        "/assets/tweets/1.png",
        "/assets/tweets/2.png",
        "/assets/tweets/3.png",
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative md:h-screen h-[60vh] w-full flex flex-col items-center justify-center overflow-visible">
            <img
                src="/assets/suitards.png"
                alt="Suitards background"
                className="absolute inset-0 w-full h-full object-cover hidden md:block"
            />
            <img
                src="/assets/suitards-mweb.png"
                alt="Suitards background"
                className="absolute inset-0 w-full block md:hidden"
            />
            <div
                className="absolute inset-0 bg-gradient-to-b from-black to-transparent pointer-events-none"
                style={{ height: "35%" }}
            ></div>
            <img
                src="/assets/stardio-house.png"
                alt="Stardio House"
                className="absolute top-20 md:top-28 md:translate-x-16 md:w-1/2"
            />

            <div className="z-10 absolute md:w-[32%] md:h-[32%] w-[65%] h-[25%] md:top-64 md:transform md:left-1/2 md:-translate-x-44 top-40 right-0 bg-black bg-opacity-50 overflow-hidden">
                {slides.map((slide, index) => (
                    <img
                        key={index}
                        src={slide}
                        alt={`Slide ${index + 1}`}
                        className={`w-full h-full absolute transition-opacity duration-500 ${index === currentSlide ? "opacity-100" : "opacity-0"
                            }`}
                    />
                ))}
            </div>
        </div>
    );
};

export default Suitard;
