import React from "react";

const SafetySection: React.FC = () => {
    return (
        <section className="py-10 text-black text-center">
            <div className="container mx-auto">
                <div className="flex flex-col items-center mb-10">
                    <h2 className="text-[100px] font-['Franchise']">
                        5/5 SAFE
                    </h2>
                    <p className="-mt-5 text-lg md:px-0 px-5">
                        Yeai've been writing move for 2 years, i know my shit
                    </p>
                </div>
                <img
                    src="/assets/safety-score.png"
                    alt="Safety"
                    className="md:w-1/2 mt-4 m-auto"
                />

                <div className="text-center mt-16 px-12 md:px-0">
                    <p className="text-lg md:text-xl">
                        $STD is a tribute to all these legends! Early adopters
                        of tech they don’t know anything about but have the
                        balls to take the plunge and the heart to stick around!
                        The suitard page’s goal is to be a hall of fame for
                        these personalities! There’s much more coming, dNFTs,
                        dank merch, branded edibles, whatnot
                    </p>
                    <p className="text-lg md:text-xl mt-10">
                        So as you’ve always done suitardio - ape the coin, fuck
                        around and find out! It’s time for suifrens cto!!!!
                    </p>
                </div>
            </div>
        </section>
    );
};

export default SafetySection;
