import React from 'react';

const TokenomicsSection: React.FC = () => {
    return (
        <section className="py-10 mt-10 px-5 md:px-0" id="tokenomics">
            <div className="mx-auto flex justify-center">
                <img src="/assets/tokenomics.svg" alt="Tokenomics" />
            </div>
        </section>
    );
};

export default TokenomicsSection;